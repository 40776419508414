import React, { useState } from 'react';
import Layout from '../Layout/Layout';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  IconButton,
  TextField,
  Hidden,
  Button,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

export default function Products() {
  const [products, setProducts] = useState([
    { id: 1, sold: 4, name: 'Product 1', price: '$10.00', salePrice: '$8.00', quantity: 20, editable: false, image: 'https://theholisticculture.com/cdn/shop/collections/intimate_care_lifestyle_image.jpg?v=1690919406&width=750' },
    { id: 2, sold: 42, name: 'Product 2', price: '$15.00', salePrice: '$12.00', quantity: 15, editable: false, image: 'https://theholisticculture.com/cdn/shop/collections/cloves_hibiscus.jpg?v=1690892830&width=750' },
    { id: 2, sold: 42, name: 'Product 2', price: '$15.00', salePrice: '$12.00', quantity: 15, editable: false, image: 'https://theholisticculture.com/cdn/shop/collections/cloves_hibiscus.jpg?v=1690892830&width=750' },
  ]);

  const handleEditClick = (id) => {
    setProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.id === id ? { ...product, editable: !product.editable } : product
      )
    );
  };

  const handleDeleteClick = (id) => {
    setProducts((prevProducts) => prevProducts.filter((product) => product.id !== id));
  };

  return (
    <Layout>
      <div className='p-5'>
     <div className='flex py-5 flex-row items-center justify-between'>
     <h1 className='font-bold text-xl'>Product List</h1>
        <Button variant="contained">
          Add Product
        </Button>
     </div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <Hidden smDown>
                  <TableCell>Actions</TableCell>
                </Hidden>
                <TableCell>Image</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Sale Price</TableCell>
                <TableCell>Total Sold</TableCell>
                <TableCell>Quantity</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products.map((product, index) => (
                <TableRow key={product.id} className={index % 2 === 0 ? 'bg-daefault_color' : 'odd-row'}>
                  <TableCell>
                    {product.editable ? (
                      <>
                        <IconButton onClick={() => handleEditClick(product.id)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => handleDeleteClick(product.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </>
                    ) : (
                      <Checkbox />
                    )}
                  </TableCell>
                  <TableCell>
                    <img src={product.image} alt={product.name} width="50" height="50" />
                  </TableCell>
                  <TableCell>
                    {product.editable ? (
                      <TextField
                        fullWidth
                        value={product.name}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      product.name
                    )}
                  </TableCell>
                  <TableCell>
                    {product.editable ? (
                      <TextField
                        fullWidth
                        value={product.price}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      product.price
                    )}
                  </TableCell>
                  <TableCell>
                    {product.editable ? (
                      <TextField
                        fullWidth
                        value={product.salePrice}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      product.salePrice
                    )}
                  </TableCell>
                  <TableCell>
                    {product.editable ? (
                      <TextField
                        fullWidth
                        value={product.sold}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      product.sold
                    )}
                  </TableCell>
                  <TableCell>
                    {product.editable ? (
                      <TextField
                        fullWidth
                        value={product.quantity}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      product.quantity
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Layout>
  );
}
