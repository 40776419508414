import React, { useState } from "react";
import Header from "./Header";
import DrawerComponent from "./Drawer";
import Footer from "./Footer";

export default function Layout({ children }) {
  const [toggle, setToggle] = useState(false)
  return (
    <div>
      <Header onToggle={() => setToggle(!toggle)} />
      <div className="bg-gray-100 rounded-tl-lg absolute top-[10vh] lg:left-[20vw] h-full w-full  lg:w-[80%]">{children}</div>

      <Footer />
      <div className="relative hidden md:block">
        <DrawerComponent />
      </div>
      {toggle && <div className="fixed top-0 left-0 z-50 bg-white  h-[100vh]">
        <DrawerComponent />
      </div>}
    </div>
  );
}
