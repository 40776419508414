import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import instance from "../../config/axios";

const initialState = {
  communities: [],
  loading: false,
  error: false,
  success: "",
};
const token = localStorage.getItem('token')


export const getCommunities = createAsyncThunk("community/get", async () => {
  try {
    const response = await instance.get("/community/get",{
      headers:{
        "Authorization":`Bearer ${token}`
      }
    });
    return response.data.data; // Assuming the API response is an array of teams
  } catch (error) {
    throw error;
  }
});

export const communityDeleteAsync = createAsyncThunk(
  "community/delete",
  async (editId, { rejectWithValue }) => {
    try {
      const response = await instance.delete(`/community/delete/${editId}`,{
        headers:{
          "Authorization":`Bearer ${token}`
        }
      });

      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const communityAsync = createAsyncThunk(
  "community/update",
  async ({ data, update, editId }, { rejectWithValue }) => {
    try {
      console.log(update, editId, data);
      const response = await instance.post(
        update ? `/community/update/${editId}` : "/community/add",
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization":`Bearer ${token}`
          },
        }
      );

      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const communitySlice = createSlice({
  name: "community",
  initialState,
  reducers: {
    setCommunities: (state, action) => {
      state.communities = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCommunities.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuth = true;
        state.communities = action.payload;
      })
      .addCase(getCommunities.rejected, (state, action) => {
        state.loading = false;

        state.error = action.payload.message;
      })
      .addCase(getCommunities.pending, (state, action) => {
        state.loading = true;
        state.error = null;
        state.success = "";
      })
      .addCase(communityAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuth = true;
        const existingIndex = state.communities.findIndex(
          (i) => action.payload._id === i._id
        );
        if (existingIndex !== -1) {
          state.communities[existingIndex] = action.payload;
          state.success = "added";
        } else {
          state.communities.push(action.payload);
          state.success = "Updated";
        }
       
      })
      .addCase(communityAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(communityAsync.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(communityDeleteAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuth = true;
        state.communities = state.communities.filter(i => i._id !== action.payload);
        state.success = "deleted";
      })
      .addCase(communityDeleteAsync.rejected, (state, action) => {
        state.loading = false;

        state.error = action.payload.message;
      })
      .addCase(communityDeleteAsync.pending, (state, action) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      });
  },
});

export const { setCommunities } = communitySlice.actions;
export default communitySlice.reducer;
