import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import "react-calendar/dist/Calendar.css";
import Layout from "../Layout/Layout";
import TextField from "@mui/material/TextField";
import { Paper, Button, Alert } from "@mui/material";
import { IconBrandGoogle, IconBrandZoom, IconTrash } from "@tabler/icons-react";
import { fetchTeam, updateTeam } from "../Redux/features/team";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import instance from "../config/axios";
import _ from 'lodash';
import BookingSlots from "./bookingSlots";
import Physical_form from "./Physical_form";


export default function TeamDetail({ token }) {

  const [enablePhysicalAddress, setEnablePhysicalAddress] = useState(false);
  const [formFields, setFormFields] = useState({
    address: '',
    address2: '',
    city: '',
    state: '',
    country: 'UAE',
  });

  const params = useParams();
  const dispatch = useDispatch();
  const isAdmin = useSelector((state) => state.admin.isAdmin);
  const team = useSelector((state) => state.team.team);
  const error = useSelector((state) => state.team.teamEvents.error);
  const loading = useSelector((state) => state.team.teamEvents.loading);
  const success = useSelector((state) => state.team.teamEvents.success);
  const [hasGoogleCalender, setGoogleCalender] = useState(false);
  const [connectedToZoom, setconnectedToZoom] = useState(false);
  const [checked, setChecked] = useState(false)




  const [weeklyHours, setWeeklyHours] = useState({
    Monday: [{ from: "09:00", to: "10:00" }],
    Tuesday: [{ from: "09:00", to: "17:00" }],
    Wednesday: [{ from: "09:00", to: "17:00" }],
    Thursday: [{ from: "09:00", to: "17:00" }],
    Friday: [{ from: "09:00", to: "17:00" }],
    Saturday: [{ from: "09:00", to: "17:00" }],
    Sunday: [{ from: "09:00", to: "17:00" }],
  });

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const today = new Date();

  // Calculate the date for tomorrow
  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 5);


  const [dateRange, setDateRange] = useState([today, tomorrow]);
  const [disabledTiles, setDisabledTiles] = useState([]);

  const [teamData, setTeamData] = useState({
    name: "",
    email: "",
    id:""
  });
  const [errors, setErrors] = useState({
    name: false,
    email: false,
  });



  useEffect(() => {
    dispatch(fetchTeam(params.id));
  }, [params]);

  useEffect(() => {
    setTeamData({
      email: team.email,
      name: team.name,
      id:team._id
    });
   
    setGoogleCalender(team.hasGoogleCalender)

    if (team.availability) {
      setWeeklyHours(team.availability);
    }
    if (team.daterange) {
      setDateRange(team.daterange);
    }
    if (team.enablePhysicalAddress) {
      setChecked(true)
      setFormFields(team.physicalAddress)
    }
    if (team.disabledTiles) {
      setDisabledTiles(team.disabledTiles);
    }
    if (team.hasZoomAccount) {
      setconnectedToZoom(true);
    }
  }, [team]);



  const handleFormSave = (e, formadata) => {
    setEnablePhysicalAddress(e)
    setFormFields(formadata)
  }



  const handleInputChange = (e) => {
    setTeamData({
      ...teamData,
      [e.target.name]: e.target.value,
    });
  };

  const handleGoogleToken = async () => {
    try {
      if (params.id) {
        await localStorage.setItem("userid", params.id);
      }
      const res = await instance.get('/team/calender/oauth')

      const jsonData = res.data

      // Extract the URL from the response
      const redirectUrl = jsonData.data;
      window.location.href = redirectUrl;
    } catch (err) {
      console.log(err);
    }
  };

  const handleZoomToken = async () => {
    try {
      if (params.id) {
        await localStorage.setItem("userid", params.id);
      }

      const redirectUrl =
        "https://zoom.us/oauth/authorize?client_id=SQ_YRukQgiYbP18LzFyGg&response_type=code&redirect_uri=https://theholoaticculture.onrender.com/zoom/token";
      window.location.href = redirectUrl;
    } catch (err) {
      console.log(err);
    }
  };



  const handleSave = async () => {
    dispatch(updateTeam({
      availability: weeklyHours,
      team: teamData,
      daterange: dateRange,
      disabled: disabledTiles,
      physicalAddress: formFields,
      enablePhysicalAddress: checked
    }))
  };

  return (
    <Paper className="m-5 md:m-10">
      <div className="px-5 pt-5">
        <h1>Edit Team Memeber</h1>
      </div>
      <div className="flex flex-col p-5 items-center justify-center w-[100%]">
        <TextField
          id="outlined-basic"
          fullWidth
          disabled={!isAdmin}
          style={{ marginBottom: 20 }}
          label="Name"
          name="name"
          variant="outlined"
          value={teamData.name}
          error={errors.name} // This assumes errors.name is a boolean
          onChange={handleInputChange}
          helperText={errors.name ? "Please fill this field" : ""}
        />
        <TextField
          id="outlined-basic"
          disabled={!isAdmin}
          fullWidth
          name="email"
          label="Email"
          value={teamData.email}
          variant="outlined"
          onChange={handleInputChange}
          error={errors.email} // This assumes errors.email is a boolean
          helperText={errors.email ? "Please Enter valid email" : ""}
        />
      </div>


      <div className="p-5">
        <div>
          <h1>Physical Address</h1>


          <Physical_form checked={checked} handleChange={handleChange} formfields={formFields} enablePhysicalAddress={enablePhysicalAddress} handleSave={handleFormSave} />


        </div>
      </div>



      <div className="p-5">
        <h1 className="font-4xl text-lg">Availablity</h1>

        <BookingSlots disabledTiles={disabledTiles} setDisabledTiles={setDisabledTiles} weeklyHours={weeklyHours} setDateRange={setDateRange} dateRange={dateRange} setWeeklyHours={setWeeklyHours} />
        <div>
          <h1 className="my-5">Connect your calenders</h1>
          <div>

            <button disabled={hasGoogleCalender} onClick={handleGoogleToken} className="gsi-material-button">
              <div className="gsi-material-button-state"></div>
              <div className="gsi-material-button-content-wrapper">
                <div className="gsi-material-button-icon">
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 48 48"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    style={{ display: 'block' }}
                  >
                    <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                    <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                    <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                    <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                    <path fill="none" d="M0 0h48v48H0z"></path>
                  </svg>
                </div>
                <span className="gsi-material-button-contents">Sign in with Google</span>
                <span style={{ display: 'none' }}>Sign in with Google</span>
              </div>
            </button>

            {hasGoogleCalender && <IconButton onClick={()=>setGoogleCalender(false)}>
              <IconTrash />
            </IconButton>}
          </div>

          {/* <div className="mt-5 flex flex-row items-center justify-start">
            <a className="w-[200px]" onClick={handleZoomToken} rel="noopener noreferrer"><img src="https://marketplacecontent.zoom.us/zoom_marketplace/img/add_to_zoom.png" className="w-[180px]" alt="Add to ZOOM" /></a>
            {!connectedToZoom && (
              <IconButton
                onClick={() => {
                  setconnectedToZoom(false);
                }}
              >
                <IconTrash />
              </IconButton>
            )}
          </div> */}

        </div>
        {error && (
          <Alert style={{ marginTop: 10 }} severity="error">
            {error}
          </Alert>
        )}

        {success && (
          <Alert style={{ marginTop: 10 }} severity="success">
            {success}
          </Alert>
        )}
        <div className="my-10">
          <Button onClick={handleSave} color="secondary" variant="contained">
            {loading ? "Loading" : " Save"}
          </Button>
          <Link to={"/team"}>
            <Button
              style={{
                marginLeft: 10,
              }}
              color="primary"
              variant="contained"
            >
              Close
            </Button>
          </Link>
        </div>
      </div>
    </Paper>
  );
}








