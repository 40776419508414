import React from "react";
import Drawer from "@mui/material/Drawer";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";



export default function DrawerComponent({ items }) {
  const location = useLocation();
  const isAdmin = useSelector(state => state.admin.isAdmin)

  const drawerItems = [
    { title: "Services",display:isAdmin },
    { title: "Bookings",display:true },
    { title: "Calender",display:true }, // Corrected spelling to match route
    { title: "Team" ,display:true},
    { title: "Community",display:isAdmin },
  ];

  return (
    <div className="fixed top-20 p-5 bg-white w-[100%] md:w-[20vw] left-0 h-[100vh]">
      <div>
        <div>
          <Link to="/">
            <div className={`cursor-pointer ${
                  location.pathname === `/`
                    ? "bg-daefault_color"
                    : "hover:bg-daefault_color"
                } py-5 flex items-center justify-center w-full`}>
              <h4>DashBoard</h4>
            </div>
          </Link>
          {drawerItems.filter(i => i.display).map((item, index) => (
            <Link to={`/${item.title.toLowerCase()}`} key={index}>
              <div
                className={`cursor-pointer ${
                  location.pathname === `/${item.title.toLowerCase()}`
                    ? "bg-daefault_color"
                    : "hover:bg-daefault_color"
                } py-5 flex items-center justify-center w-full border-t border-secondary_color`}
              >
                <h4>{item.title}</h4>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}
