import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import instance from "../../config/axios";

const initialState = {
  user: {},
  isAdmin: false,
  token: "",
  isAuth: false,
  loading: false,
  error: null,
};

export const signinAsync = createAsyncThunk(
  "admin/signin",
  async ({ username, password }, { rejectWithValue }) => {
    try {
      const response = await instance.post("/user/signin", {
        username,
        password,
      });
      localStorage.setItem('token', response.data.token)
      window.location.reload()
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setAuth: (state, action) => {
      state.isAuth = action.payload.isAuth;
      state.token = action.payload.token;
      state.isAdmin = action.payload.isAdmin;
      state.user = action.payload.user;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signinAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(signinAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuth = true;
        state.user = action.payload.user;
        state.isAdmin = action.payload.user.admin;
        state.token = action.payload.token
      })
      .addCase(signinAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      });
  },
});

export const { setAuth } = adminSlice.actions;
export default adminSlice.reducer;
