import React, { useEffect, useState } from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  Switch,
} from "react-router-dom"; // Import necessary components
import Home from "../pages/Home";
import Products from "../pages/Products";
import Services from "../pages/Services";
import Team from "../pages/Team";
import teamDetail from "../pages/teamDetail";
import processToken from "../pages/processToken";
import ProtectedRoutes from "../PrivateRoute/PrivateRoute";
import Login from "../pages/Auth";
import ServiceDetail from "../pages/ServiceDetail";
import CalenderPage from "../pages/Calender";
import Bookings from "../pages/Bookings";
import instance from "../config/axios";
import { useDispatch } from "react-redux";
import { setAuth } from "../Redux/features/admin";
import Community from "../pages/Community";
import Privacy_policy from "../pages/Privacy_policy";
import Terms_condition from "../pages/Terms_confition";



export default function AppRouter() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const verifToken = async () => {


    try {
      const token = localStorage.getItem("token");
      if (token) {
        const res = await instance.get("/user/verify", {
          headers: {
            "Authorization": `Bearer ${token}`
          }
        });



        dispatch(
          setAuth({
            isAuth: true,
            user: res.data.name,
            isAdmin: res.data.admin,
          })
        );
      } else {
        dispatch(
          setAuth({
            isAuth: false,
            user: {},
            isAdmin: false,
          })
        );
      }
    } catch (err) {
      dispatch(
        setAuth({
          isAuth: false,
          user: {},
          isAdmin: false,
        })
      );
    }
  };


  useEffect(() => {
    verifToken();
  });

  return (
    <Router>
      <Routes>
        <Route element={<ProtectedRoutes />}>
          <Route exact path="/" Component={Home} />
          <Route exact path="/Products" Component={Products} />
          <Route exact path="/services" Component={Services} />
          <Route exact path="/services/:id" Component={ServiceDetail} />
          <Route exact path="/team" Component={Team} />
          <Route exact path="/team/:id" Component={teamDetail} />
          <Route exact path="/calender" Component={CalenderPage} />
          <Route exact path="/bookings" Component={Bookings} />
          <Route exact path="/community" Component={Community} />
        </Route>
        <Route exact path="/privacy-policy" Component={Privacy_policy} />
        <Route exact path="/terms" Component={Terms_condition} />
        <Route exact path="/:id/token" Component={processToken} />
        <Route exact path="/login" Component={Login} />
      </Routes>
    </Router>
  );
}
