// src/features/teamSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import instance from "../../config/axios";

const initialState = {
  teams: [],
  teamsevents:{
    loading:false,
    error:'',
    success:''
  },
  team: {},
  teamEvents:{
    loading:false,
    error:'',
    success:''
  },
  services:[],
  service:{},
  serviceEvents:{
    loading:false,
    error:'',
    success:''
  },
  status: "idle",
  error: null,
};

const token = localStorage.getItem('token')
export const fetchTeams = createAsyncThunk("team/fetchTeams", async () => {
  try {
    const response = await instance.get("/team/get",{
      headers:{
        "Authorization":`Bearer ${token}`
      }
    });
    return response.data; // Assuming the API response is an array of teams
  } catch (error) {
    throw error;
  }
});

export const fetchTeam = createAsyncThunk("team/fetchTeam", async (id) => {
  try {
    const response = await instance.get(`/team/get/${id}`,{
      headers:{
        "Authorization":`Bearer ${token}`
      }
    });
    return response.data; // Assuming the API response is an array of teams
  } catch (error) {
    throw error;
  }
});

export const createTeam = createAsyncThunk(
  "team/createTeam",
  async (teamData) => {
    try {
      const response = await instance.post("/team/create", teamData,{
        headers:{
          "Authorization":`Bearer ${token}`
        }
      });

      return response.data; // Assuming the API response is the created team data
    } catch (error) {
     
      throw error.response.data.error;
    }
  }
);


export const updateTeam = createAsyncThunk(
    "team/update",
    async (teamData) => {
      try {
        const response = await instance.put("/team/update", teamData,{
          headers:{
            "Authorization":`Bearer ${token}`
          }
        });
  
        return response.data.data; // Assuming the API response is the created team data
      } catch (error) {
        throw error;
      }
    }
  );


  export const getServices = createAsyncThunk(
    "team/services",
    async (teamData) => {
      try {
        const response = await instance.get("/team/services", teamData,{
          headers:{
            "Authorization":`Bearer ${token}`
          }
        });
  
        return response.data.data; // Assuming the API response is the created team data
      } catch (error) {
        throw error;
      }
    }
  );



  export const getService = createAsyncThunk(
    "team/service",
    async (slug) => {
      try {
        const response = await instance.get(`/team/services/${slug}`,{
          headers:{
            "Authorization":`Bearer ${token}`
          }
        });
  
        return response.data.data; // Assuming the API response is the created team data
      } catch (error) {
        throw error;
      }
    }
  );

  export const updateService = createAsyncThunk(
    "team/service/update",
    async (data) => {
      try {
        const response = await instance.put(`/team/update/handler`,data,{
          headers:{
            "Authorization":`Bearer ${token}`
          }
        });
  
        return response.data.data; // Assuming the API response is the created team data
      } catch (error) {
        throw error;
      }
    }
  );





  

const teamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {
    setTeams: (state, action) => {
      state.teams = action.payload;
    },
    clearState:(state,action) => {
      state.teamsevents.success = ''
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTeams.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTeams.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.teams = action.payload;
      })
      .addCase(fetchTeams.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(createTeam.pending, (state) => {
        state.teamsevents.loading = true;
        state.teamsevents.error = ''
        state.teamsevents.success = ''
      })
      .addCase(createTeam.fulfilled, (state, action) => {
        state.teamsevents.loading = false;
        state.teamsevents.error = ''
       state.teams.push(action.payload.user)
       state.teamsevents.success = action.payload.message
      })
      .addCase(createTeam.rejected, (state, action) => {
        state.teamsevents.loading = false;
        state.teamsevents.error = action.error.message;
      })
      .addCase(fetchTeam.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTeam.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.team = action.payload
      })
      .addCase(fetchTeam.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      }).addCase(updateTeam.pending, (state) => {
        state.teamEvents.loading = true;
     
      })
      .addCase(updateTeam.fulfilled, (state, action) => {
        state.teamEvents.loading = false;
        state.teamEvents.error = false;
        state.teamEvents.success = 'Updated successfully'
      })
      .addCase(updateTeam.rejected, (state, action) => {
        state.teamEvents.loading = false
        state.error = action.error.message;
      }).addCase(getServices.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getServices.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.services = action.payload
      })
      .addCase(getServices.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      }).addCase(getService.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getService.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.service = action.payload
      })
      .addCase(getService.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      }).addCase(updateService.pending, (state) => {
        state.serviceEvents.loading = true;
        state.serviceEvents.error = ''
        state.serviceEvents.success = ''
      })
      .addCase(updateService.fulfilled, (state, action) => {
        state.serviceEvents.success = "event is connected successfully";
        state.serviceEvents.loading = false;
      })
      .addCase(updateService.rejected, (state, action) => {
        state.serviceEvents.loading = false;
        state.serviceEvents.error = action.error.message;
      });

  },
});

export const { setTeams,clearState } = teamSlice.actions;
export default teamSlice.reducer;
