import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setShowSnackBar } from "../Redux/features/snackBar";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomizedSnackbars() {
    const snacBar = useSelector((state) => state.snacBar);
    const dispatch = useDispatch();
  
 

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(
        setShowSnackBar({
          show: false,
          message: "",
          status: "",
        })
      );
  };

 

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
    
      <Snackbar open={snacBar.show} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={snacBar.status} sx={{ width: '100%' }}>
        {snacBar.message}
        </Alert>
      </Snackbar>
    
    </Stack>
  );
}
