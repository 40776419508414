import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Layout from "../Layout/Layout";
import { Paper, Typography, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getService, updateService } from "../Redux/features/team";
import { Alert } from "@mui/material";

import TextField from "@mui/material/TextField";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Autocomplete from "@mui/material/Autocomplete";
import ServicesForm from "../Components/servicesForm";
import { setShowSnackBar } from "../Redux/features/snackBar";

export default function ServiceDetail() {
  const params = useParams();
  const state = useSelector((state) => state.team.service);
  const teams = useSelector((state) => state.team.teams);
  const error = useSelector((state) => state.team.serviceEvents.error);
  const loading = useSelector((state) => state.team.serviceEvents.loading);
  const success = useSelector((state) => state.team.serviceEvents.success);

  const [selectedTeam, setSelectedTeam] = useState("");
  const [autoComplet, setAutoComplete] = useState([]);
  const [configureVariants, setConfigureVariantState] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (success) {
      dispatch(
        setShowSnackBar({
          show: success,
          message: success,
          status: "success",
        })
      );
    }
  }, [success]);

  useEffect(() => {
    if (error) {
      dispatch(
        setShowSnackBar({
          show: error,
          message: error,
          status: "error",
        })
      );
    }
  }, [error]);

  useEffect(() => {
    dispatch(getService(params.id));
  }, []);

  useEffect(() => {
    if (state) {
      setSelectedTeam(state.email);
    }
    if (!state.services) {
      if (
        state.variants &&
        state.variants.edges &&
        state.variants.edges.length > 0
      ) {
        const res = state.variants.edges.map((item) => {
          return {
            serviceName: item.node.title,
            id: item.node.id,
            schedule: {
              duration: 0,
              location: "google",
            },
            appointments: {
              beforeMinutes: 0,
              afterMinutes: 0,
              cutOffTime: 0,
              maxDays: 360,
            },
            daterange: [new Date(), new Date()],
            availability:  {
              Monday: [{ from: "09:00", to: "10:00" }],
              Tuesday: [{ from: "09:00", to: "17:00" }],
              Wednesday: [{ from: "09:00", to: "17:00" }],
              Thursday: [{ from: "09:00", to: "17:00" }],
              Friday: [{ from: "09:00", to: "17:00" }],
              Saturday: [{ from: "09:00", to: "17:00" }],
              Sunday: [{ from: "09:00", to: "17:00" }],
            },
            customizedSlots: false
          };
        });
        setConfigureVariantState(res);
      }
    } else {
      setConfigureVariantState(state.services);
    }
  }, [state]);

  const handleUpdate = (e) => {

    const updatedVariants = configureVariants.map((variant) => {
      if (variant.serviceName === e.serviceName) {
        return e;
      }
      return variant;
    });

    setConfigureVariantState(updatedVariants);

  };

  useEffect(() => {
    if (teams.length > 0) {
      const newValue = teams
        .filter((i) => i.handle === undefined || i.handle === "")
        .map((item) => {
          return {
            label: item.email,
          };
        });
      setAutoComplete(newValue);
    }
  }, [teams]);

  const handleSave = async () => {
    dispatch(
      updateService({
        email: selectedTeam,
        handler: params.id,
        configureVariants,
      })
    );
  };

  const handleTeamChange = (event, newValue) => {
    if (newValue) {
      setSelectedTeam(newValue.label);
    } else {
      setSelectedTeam("");
    }
  };

  return (
    <Layout>
      <div className="p-5 md:p-10">
        <Paper elevation={2} style={{ padding: 16 }}>
          <div className="flex flex-row items-center justify-between">
            <Typography variant="h5" gutterBottom>
              Edit Details
            </Typography>
            <Button
              onClick={handleSave}
              style={{
                marginTop: 10,
              }}
              color="primary"
            >
              {loading ? "Loading..." : " Save"}
            </Button>
          </div>

          <div className="w-full">
            <div className="mb-5 w-full">
              <Autocomplete
                fullWidth
                disablePortal
                id="combo-box-demo"
                options={autoComplet}
                value={selectedTeam}
                onChange={handleTeamChange} // Handle change here
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Owner" />
                )}
              />
            </div>

            {configureVariants.length > 0 &&
              configureVariants.map((item, index) => (
                <ServicesForm defaultavailability={state.availability} defaultdaterange={state.daterange}  handleUpdate={handleUpdate} enablePhysicalAddress={state.enablePhysicalAddress} item={item} />
              ))}
          </div>

          {error && (
            <Alert style={{ marginTop: 10 }} severity="error">
              {error}
            </Alert>
          )}
        </Paper>
      </div>
    </Layout>
  );
}
