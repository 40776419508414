import React from 'react';
import LogoSvg from '../Assets/logo/logo.svg';
import MenuIcon from '@mui/icons-material/Menu';
import { Avatar, TextField, IcontButton } from '@mui/material';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { IconLogout, IconSettings2 } from '@tabler/icons-react';
import { Menu } from '@mui/icons-material';


export default function Header({ onToggle }) {

  const handleLogout = () => {
    localStorage.removeItem('token')
    window.location.reload()
  }
  return (
    <div className='fixed w-full z-[1500] bg-white'>

      <div className='flex items-center justify-between w-[90%] m-auto md:justify-between'>
        <div className='hidden md:block'></div>
        <div className='md:hidden'>
          <Menu onClick={onToggle} />
        </div>
        <div className='md:w-[1%]'>
          <div className='w-[150px] h-[80px]'>
            <img className='w-full h-full' src={LogoSvg} alt='logo' />
          </div>

        </div>
        <div onClick={handleLogout} className='flex flex-row items-center justify-center'>
          <span>Logout</span>
          <IconLogout />
        </div>
      </div>

    </div>
  );
}
