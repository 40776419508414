import React, { useEffect, useState } from "react";
import instance from "../config/axios";

import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import LottieFiles from "../Components/LottieFiles";
import LottieAnimation from "../Assets/animation/search.json";
import LottieSuccess from "../Assets/animation/success.json";
import LottieError from "../Assets/animation/error.json";
import { setShowSnackBar } from "../Redux/features/snackBar";

export default function ProcessToken() {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const params = useParams();

  const sumbitToken = async (token) => {
    const id = await localStorage.getItem("userid");
    try {
      if (params.id === "zoom") {
        const res = await instance.post("/zoom/verify/token", {
          token: token,
          id: id,
        });
        if (res) {
          await localStorage.setItem(
            "auth_token",
            JSON.stringify(res.data.data)
          );

          setSuccess(true);
          navigate(`/team/${id}`);
        }
      }

      if (params.id === "google") {
        const res = await instance.post("/team/calender/oauth/verify", {
          token: token,
          id,
        });
        if (res) {
          setSuccess(true);
          setShowSnackBar({
            show: true,
            message: "Successfully logged in",
            status: 'success'
          })
          setTimeout(() => {
            // Navigate to the new URL after 3000 milliseconds (3 seconds)
            navigate(`/team/${id}`);
          }, 3000);
        }
      }
    } catch (err) {
      console.log(err)
      setError(true);
      navigate(`/team/${id}`);
    }
  };

  useEffect(() => {
    // Function to extract token from the URL
    const extractTokenFromURL = () => {
      // Get the current URL
      const url = new URL(window.location.href);

      // Extract the 'code' parameter from the URL
      const code = url.searchParams.get("code");

      sumbitToken(code);
    };

    // Call the function to extract token when the component mounts
    extractTokenFromURL();
  }, []);

  return (
    <div className="flex h-[100vh] flex-col w-[100vw] items-center justify-center">
      <div className="w-[400px] h-[400px]">
        <LottieFiles src={success ? LottieSuccess : LottieAnimation} />
      </div>
      <h1 className="text-3xl font-bold">
        Verifying we will redirect you shortly
      </h1>
    </div>
  );
}
