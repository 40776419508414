import React from 'react'
import { Player, Controls } from '@lottiefiles/react-lottie-player';

export default function LottieFiles({src}) {
  return (
    <Player
    autoplay
    loop
    src={src}
    style={{ height: '100%', width: '100%' }}
  >
  </Player>
  )
}
