import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom'; // Import BrowserRouter
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './App.css';
import Home from './pages/Home';
import AppRouter from './Router/Router';

function App() {

  return (
    <AppRouter />
  );
}

export default App;
