import React, { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import Button from "@mui/material/Button";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useDispatch, useSelector } from "react-redux";
import { communityAsync } from "../Redux/features/community";
import instance from "../config/axios";
import { setShowSnackBar } from "../Redux/features/snackBar";

export default function CommunityEdit({ editId, handleClose }) {
  const loading = useSelector((state) => state.community.loading);
  const error = useSelector((state) => state.community.error);
  const success = useSelector((state) => state.community.success);
  const [text, setText] = useState("");
  const dispatch = useDispatch();
  const [image, setImage] = useState(null);
  const [rawImage, setrawImage] = useState(null);
  const [formData, setFormData] = useState({
    title: "",
    link: "",
    rounded: false,
  });

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["blockquote", "code-block"],
      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      [{ align: [] }],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "color",
    "background",
    "align",
    "code-block",
  ];

  const fetchExistingData = async () => {
    try {
      const res = await instance.get(`/community/get/${editId}`);
      const community = res.data.data;
      setFormData({
        title: community.title,
        link: community.link,
        rounded: community.rounded,
      });
      setText(community.description);
      setImage(
        `https://theholisticculture.com/community/${community.imageurl}`
      );
    } catch (err) {}
  };

  useEffect(() => {
    if (success === "updated") {
      setShowSnackBar({
        show: true,
        message: "Updated successfully",
        status: "success",
      });
    }
    if (success === "added") {
      setShowSnackBar({
        show: true,
        message: "Added successfully",
        status: "success",
      });
    }
  }, [success]);

  useEffect(() => {
    if (error) {
      setShowSnackBar({
        show: true,
        message: "Something went wrong Please try again",
        status: "error",
      });
    }
  }, [error]);

  useEffect(() => {
    if (editId !== "") {
      fetchExistingData();
    }
  }, [editId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setrawImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCheckboxChange = () => {
    setFormData({
      ...formData,
      rounded: !formData.rounded,
    });
  };

  const handlesSave = () => {
    const updatedData = {
      ...formData,
      description: text,
    };
    const reqData = JSON.stringify(updatedData);

    const data = new FormData();
    if (rawImage) {
      data.append("image", rawImage);
    }
    data.append("form", reqData);

    if (editId) {
      dispatch(communityAsync({ data, update: true, editId }));
    } else {
      dispatch(communityAsync({ data }));
    }
  };

  return (
    <div className="w-full h-full">
      <h1 className="text-center font-bold text-2xl mt-10">Community</h1>
      <div className="bg-white p-10 mt-5">
        <TextField
          id="outlined-basic"
          fullWidth
          style={{ marginBottom: 20 }}
          label="Title"
          name="title"
          variant="outlined"
          value={formData.title}
          onChange={handleChange}
        />
        <div className="h-[250px] mb-20">
          <ReactQuill
            style={{
              height: "100%",
            }}
            theme="snow"
            value={text}
            modules={modules}
            formats={formats}
            onChange={(e) => {
              setText(e);
            }}
          />
        </div>
        <TextField
          id="outlined-basic"
          fullWidth
          style={{ marginBottom: 20 }}
          label="Link"
          name="link"
          variant="outlined"
          value={formData.link}
          onChange={handleChange}
        />
        {/* Image Upload */}
        <input type="file" accept="image/*" onChange={handleImageChange} />

        <div>
          {image && (
            <div className="mt-4">
              <h3 className="text-lg font-semibold">Image Preview</h3>
              <img
                src={image}
                alt="Preview"
                className={`w-[250px] h-[250px] shadow-lg ${
                  formData.rounded ? "rounded-full" : "rounded-lg"
                }`}
                style={{ maxWidth: "100%", marginTop: "10px" }}
              />

              <div className="mt-5">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.rounded}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label={`Convert it to ${
                    formData.rounded ? "Square" : "Rounded"
                  } `}
                />
              </div>
            </div>
          )}
        </div>
        <div className="mt-5">
          {editId !== "" ? (
            <Button onClick={() => handlesSave()} variant="contained">
              {!loading ? "Update" : "Loading"}
            </Button>
          ) : (
            <Button onClick={() => handlesSave()} variant="contained">
              {!loading ? "Save" : "Loading"}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
