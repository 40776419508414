import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import instance from "../../config/axios";

const initialState = {
  meetings: [],
  loading: true,
  error: ""
};

const token = localStorage.getItem('token')

export const getMeetings = createAsyncThunk("team/fetchTeams", async () => {
  try {
    const response = await instance.get("/meeting/get/user", {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    });
    return response.data; // Assuming the API response is an array of teams
  } catch (error) {
    throw error;
  }
});

const meetingSlice = createSlice({
  name: "meetings",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMeetings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getMeetings.fulfilled, (state, action) => {
        state.loading = false;
        state.meetings = action.payload
      })
      .addCase(getMeetings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { } = meetingSlice.actions;
export default meetingSlice.reducer;
