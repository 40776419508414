import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Layout from "../Layout/Layout";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import { fetchTeams, getServices } from "../Redux/features/team";
import { Link } from "react-router-dom";
import Typography from '@mui/material/Typography';



const columns = [
  //{ field: "id", headerName: "ID", width: 80 }, // Adjust the width as needed

  {
    field: "handle",
    headerName: "Handle Name",
    width: 250, // Adjust the width as needed
  },
  {
    align: "left",
    headerAlign: "left",
    field: "title",
    headerName: "Host",
    width: 250,
  }, // Adjust the width as needed
  {
    align: "left",
    headerAlign: "left",
    field: "status",
    headerName: "Status",
    type: "string",
    width: 120,
  },
  {
    align: "left",
    headerAlign: "left",
    field: "connected",
    headerName: "Connected",
    type: "string",
    width: 120,
  }, // Adjust the width as needed
  {
    align: "center",
    headerAlign: "center",
    field: "actions",
    headerName: "Actions",
    renderCell: (params) => (
      <div>
        {/* Add your action buttons here */}
        <Link className="mr-10" to={`/services/${params.row.handle}`}>
          Edit
        </Link>
    
      </div>
    ),
    width: 250, // Adjust the width as needed
  },
];

export default function Services() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.team.services);
  useEffect(() => {
    dispatch(getServices());
    dispatch(fetchTeams());
  }, []);

  return (
    <Layout>
      <div className="p-4 md:p-10">
        <div className="flex mb-5  items-center justify-between ">
  
          <div>
            <Typography variant="h4" gutterBottom>
            Services
            </Typography>
          </div>
       
        </div>

        <div
          className="bg-white rounded-lg overflow-hidden"
          style={{ height: 400, width: "100%" }}
        >
          <DataGrid
            rows={state}
            columns={columns}
            pageSize={5} // Change this value as needed
            rowsPerPageOptions={[5, 10, 20]}
            pagination
          />
        </div>
      </div>
    </Layout>
  );
}
