import React, { useEffect } from 'react'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import Layout from '../Layout/Layout'
import { Calendar, dayjsLocalizer } from 'react-big-calendar'
import { useSelector, useDispatch } from "react-redux";
import { getMeetings } from "../Redux/features/booking";
import dayjs from 'dayjs'
import { Paper } from '@mui/material'
const localizer = dayjsLocalizer(dayjs)


export default function CalenderPage() {
  const state = useSelector((state) => state.meetings.meetings);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMeetings());
  }, []);



const events = state.data && state.data.length > 0 ? state.data.map((item,index)=>{
  return {
    title:item.name,
    start:new Date(item.slots[0]),
    end:new Date(item.slots[1])
  }
}) : [];


  return (
<Layout>
<Paper className='m-2 mt-36 md:mt-0 md:m-10 p-2' elevation={3}>
<Calendar
     localizer={localizer}
      startAccessor="start"
      events={events}
      endAccessor="end"
      style={{ height: 500 }}
    />
</Paper>
</Layout>
  )
}
