import React from 'react';
import Layout from '../Layout/Layout';
import { Link } from 'react-router-dom';

export default function Privacy_policy() {
  return (

   <div>

      <div className="max-w-2xl mt-5 bg-white mx-auto p-4">
      <Link to={'/'}>Back To Home</Link>
      <h1 className="text-2xl font-bold mb-4">Privacy Policy</h1>

      <div>
      <h1>Privacy Policy for The Holistic culture Experts</h1>

      <p>
        This Privacy Policy describes how The Holistic culture Experts collects , uses, and shares information about you and the experts you connect with when using our application and website. By using our services, you agree to the terms of this Privacy Policy.
      </p>

      {/* Information We Collect */}
      <h2>Information We Collect:</h2>

      <ol>
        <li>
          <strong>Expert Information:</strong>
          <p>When experts register with our platform, we collect information such as their name, contact details, professional qualifications, and other relevant information to facilitate their services on our platform.</p>
        </li>

        <li>
          <strong>User Information:</strong>
          <p>Users who book appointments with experts provide information such as name, email address, and other necessary details for scheduling purposes.</p>
        </li>

        <li>
          <strong>Zoom and Google Calendar Integration:</strong>
          <p>Our application integrates with Zoom and Google Calendar to facilitate video meetings and appointment scheduling. We do not collect or store any personal information from Zoom or Google Calendar. The integration is solely for the purpose of connecting users with experts seamlessly.</p>
        </li>
      </ol>

      {/* How We Use the Information */}
      <h2>How We Use the Information:</h2>

      <ol>
        <li>
          <strong>Expert Information:</strong>
          <p>Expert information is used to create and manage profiles on our platform. This includes displaying their availability and expertise to users.</p>
        </li>

        <li>
          <strong>User Information:</strong>
          <p>User information is used for scheduling appointments and facilitating communication between users and experts.</p>
        </li>

        <li>
          <strong>Zoom and Google Calendar Integration:</strong>
          <p>We use the Zoom and Google Calendar integration to enable video meetings and synchronize appointment details. No personal information is stored or accessed from Zoom or Google Calendar.</p>
        </li>
      </ol>

      {/* Do Not Sell My Personal Information */}
      <h2>Do Not Sell My Personal Information:</h2>

      <p>We do not sell any personal information, whether it belongs to experts or users. Our focus is on providing a secure and efficient platform for connecting experts with users.</p>

      {/* Security */}
      <h2>Security:</h2>

      <p>We employ industry-standard security measures to protect the information collected on our platform. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.</p>

      {/* Updates to Privacy Policy */}
      <h2>Updates to Privacy Policy:</h2>

      <p>We may update this Privacy Policy from time to time. Any changes will be posted on our website, and we encourage you to review the Privacy Policy periodically.</p>

      {/* Contact Us */}
      <h2>Contact Us:</h2>

      <p>If you have any questions or concerns about this Privacy Policy, please contact us at find@theholisticculture.com.</p>

      <p>This Privacy Policy was last updated on 31/01/2024.</p>
    </div>
    </div>
   </div>

  );
}
