import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="bg-white w-full fixed flex items-center justify-center bottom-0">
      <ul className="flex list-disc flex-row items-center">
        <li className="mr-10 underline">
          <Link to={"/privacy-policy"}>Privacy Policy</Link>
        </li>
        <li className="underline">
          <Link to={"/terms"}>Terms & Condition</Link>
        </li>
      </ul>
    </div>
  );
}
