import React, { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import { useSelector, useDispatch } from "react-redux";
import {
  communityDeleteAsync,
  getCommunities,
} from "../Redux/features/community";
import { DataGrid } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { Paper, Button, Alert } from "@mui/material";
import ReusableModal from "../Layout/Modal";
import CommunityEdit from "../Components/community_editor";
import { setShowSnackBar } from "../Redux/features/snackBar";

export default function Community() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.community.communities);
  const success = useSelector((state) => state.community.success);
  const loading = useSelector((state) => state.community.loading);
  const [open, setOpen] = useState(false);
  const [editId, setEditId] = useState("");

  useEffect(() => {
    if (success === "deleted") {
      setShowSnackBar({
        show: true,
        message: "Deleted successfully",
        status: "error",
      });
    }
  }, [success]);


  const columns = [
    {
      field: "title",
      headerName: "Title",
      width: 150, // Adjust the width as needed
    },
    {
      align: "left",
      headerAlign: "left",
      field: "imageurl",
      headerName: "Image Name",
      width: 350,
    }, // Adjust the width as needed
    {
      align: "left",
      headerAlign: "left",
      field: "createdDate",
      headerName: "Created At",
      type: "string",
      renderCell: (params) => (
        <div>
          <span>
            {dayjs(params.row.createdDate).format("DD/MM/YYYY HH:MM A")}
          </span>
        </div>
      ),
      width: 320,
    },
    {
      align: "left",
      headerAlign: "left",
      field: "Actions",
      headerName: "Actions",
      type: "string",
      renderCell: (params) => (
        <div>
          <Button
            onClick={() => {
              setEditId(params.row._id);
              setOpen(true);
            }}
          >
            Edit
          </Button>
          <Button
            onClick={() => dispatch(communityDeleteAsync(params.row._id))}
          >
            {loading ? "Loading" : "Delete"}
          </Button>
        </div>
      ),
      width: 320,
    },
  ];

  useEffect(() => {
    dispatch(getCommunities());
  }, []);

  return (
    <Layout>
      <div className="my-10 w-[90%] m-auto flex flex-row items-center justify-between">
        <h1 className="text-center font-bold text-xl">Community</h1>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setOpen(true);
            setEditId("");
          }}
        >
          Add New
        </Button>
      </div>

      <div className="bg-white w-[90%] m-auto">
        <DataGrid
          style={{
            height: 500,
          }}
          getRowId={(i) => i._id}
          rows={state}
          columns={columns}
          pageSize={5} // Change this value as needed
          rowsPerPageOptions={[5, 10, 20]}
          pagination
        />
      </div>

      <ReusableModal
        width={"70vw"}
        handleClose={() => setOpen(false)}
        actionText={"Save"}
        open={open}
        title={"Community"}
      >
        <CommunityEdit handleClose={() => setOpen(false)} editId={editId} />
      </ReusableModal>
    </Layout>
  );
}
