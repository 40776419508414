import React from "react";
import Layout from "../Layout/Layout";
import { Paper,Button } from "@mui/material";
import { Link } from "react-router-dom";

export default function Home() {
  return (
    <div>
      <Layout>
        <div className="p-4 flex items-center justify-center h-full flex-col">
          <h1 className="text-center font-bold text-2xl">Welcome To The Holisticculture Booking app</h1>

          <p className="text-center">THC-Experts Developed for Schedule Slots So User can book slots</p>
         <div className="text-center">
  <Link to={'/team'}>
  <Button >Go To Teams</Button></Link>
         </div>
        </div>
      </Layout>
    </div>
  );
}
