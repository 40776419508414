// src/app/store.js
import { configureStore } from '@reduxjs/toolkit';
import team from './features/team';
import admin from './features/admin';
import meetingSlice from './features/booking'
import snackBar from './features/snackBar';
import community from './features/community';

const store = configureStore({
  reducer: {
    team: team,
    admin:admin,
    meetings:meetingSlice,
    snacBar:snackBar,
    community:community
  },
});

export default store;
