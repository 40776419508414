import React from 'react';
import Layout from '../Layout/Layout';
import { Link } from 'react-router-dom';

export default function Terms_condition() {
  return (
<div>
<Link to={'/'}>Back To Home</Link>
<div className="max-w-2xl mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Terms of Service</h1>

      <ol className="list-decimal pl-6">
        <li>
          <strong>Acceptance of Terms</strong>
          <p>By using our The Holistic culture Experts, you agree to comply with and be bound by these Terms of Service.</p>
        </li>

        <li>
          <strong>User Account and Access</strong>
          <p>
            a. You must create an account to use the App.
            <br />
            b. You are responsible for maintaining the confidentiality of your account information.
            <br />
            c. You are responsible for all activities that occur under your account.
          </p>
        </li>

        <li>
          <strong>Appointments and Meetings</strong>
          <p>
            a. The App facilitates the creation of appointments and meetings using Google Calendar, Zoom, and Google Meet.
            <br />
            b. You authorize the App to access your Google Calendar, Zoom, and Google Meet accounts to create and manage appointments and meetings.
            <br />
            c. The App is not responsible for any changes, disruptions, or cancellations made within your Google Calendar, Zoom, or Google Meet accounts.
          </p>
        </li>

        <li>
          <strong>User Conduct</strong>
          <p>
            a. You agree to use the App in accordance with applicable laws and regulations.
            <br />
            b. You will not engage in any unlawful or prohibited activities while using the App.
          </p>
        </li>

        <li>
          <strong>Intellectual Property</strong>
          <p>All content and features of the App are owned by [Your Company]. You may not use, modify, or reproduce any part of the App without permission.</p>
        </li>

        <li>
          <strong>Disclaimer of Warranties</strong>
          <p>
            a. The App is provided "as is" without any warranties.
            <br />
            b. We do not guarantee the accuracy, completeness, or reliability of any information or content within the App.
          </p>
        </li>

        <li>
          <strong>Limitation of Liability</strong>
          <p>
            a. The Holostic Culture is not liable for any indirect, incidental, special, or consequential damages.
            <br />
            b. In no event shall our total liability exceed the amount paid by you for the use of the App.
          </p>
        </li>

        <li>
          <strong>Changes to Terms</strong>
          <p>We reserve the right to update and modify these Terms of Service at any time without notice. Continued use of the App constitutes acceptance of the modified terms.</p>
        </li>
      </ol>
    </div>

</div>
   
  );
}
