import React, { useEffect, useState } from "react";
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Alert
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { signinAsync } from "../Redux/features/admin";

import { useNavigate } from "react-router-dom";


export default function Login() {
  const dispatch = useDispatch();
  const isAuth = useSelector(state => state.admin.isAuth)
  const loader = useSelector(state => state.admin.loading)
  const error = useSelector(state => state.admin.error)
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    rememberMe: false,
  });


  const navigate = useNavigate();

  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    const fieldValue = type === "checkbox" ? checked : value;

    setFormData({
      ...formData,
      [name]: fieldValue,
    });
  };

  useEffect(() => {
    if (isAuth) {
      navigate('/')
    }
  }, [isAuth])

  const handleSubmit = (event) => {
    event.preventDefault();

    // Validate the form data
    const validationErrors = validateForm(formData);
    setErrors(validationErrors);

    // If there are no errors, you can proceed with further actions (e.g., API call)
    if (Object.keys(validationErrors).length === 0) {
      dispatch(
        signinAsync({
          username: formData.username,
          password: formData.password,
        })
      );
    }
  };

  const validateForm = (data) => {
    const errors = {};

    if (!data.username.trim()) {
      errors.username = "Username is required";
    }

    if (!data.password.trim()) {
      errors.password = "Password is required";
    }

    return errors;
  };

  return (
    <div className="flex flex-col h-[100vh] w-[100vw] justify-center items-center">
      <Paper elevation={3} className="p-8  w-96">

        <Typography variant="h5" gutterBottom>
          Login
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Username"
            fullWidth
            margin="normal"
            name="username"
            value={formData.username}
            onChange={handleChange}
            error={Boolean(errors.username)}
            helperText={errors.username}
          />
          <TextField
            label="Password"
            type="password"
            fullWidth
            margin="normal"
            name="password"
            value={formData.password}
            onChange={handleChange}
            error={Boolean(errors.password)}
            helperText={errors.password}
          />
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                name="rememberMe"
                checked={formData.rememberMe}
                onChange={handleChange}
              />
            }
            label="Remember Me"
          />
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            size="large"
            disabled={loader}
            type="submit"
          >
            {!loader ? "Login" : "Sumbiting ..."}
          </Button>

          {error && <Alert severity="error"  >
            {error.en}
          </Alert>}
        </form>
      </Paper>

    </div>
  );
}
