import React, { useEffect, useState } from "react";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import TextField from "@mui/material/TextField";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Paper, Typography, Button } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import ReusableDropdown from "./ReusableDropDown";
import _ from 'lodash';
import Physical_form from "./Physical_form";
import BookingSlots from "./bookingSlots";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { CopyAllOutlined } from "@mui/icons-material";


const dropdownOptions = [
  { label: "Google", value: "google" },
  { label: "Zoom", value: "zoom" },
  { label: "Offline", value: "offline" },
];


export default function ServicesForm({ item, handleUpdate, defaultavailability,enablePhysicalAddress, defaultdaterange }) {
  const [checked, setChecked] = useState(true);

  const [formData, setFormData] = useState({
    appointments: {
      beforeMinutes: 0,
      afterMinutes: 0,
      cutOffTime: 0,
      maxDays: 360,
    },
    schedule: {
      duration: 0,
      location: "google",
    },
    serviceName: "",
  });

  const [weeklyHours, setWeeklyHours] = useState({
    Monday: [{ from: "09:00", to: "10:00" }],
    Tuesday: [{ from: "09:00", to: "17:00" }],
    Wednesday: [{ from: "09:00", to: "17:00" }],
    Thursday: [{ from: "09:00", to: "17:00" }],
    Friday: [{ from: "09:00", to: "17:00" }],
    Saturday: [{ from: "09:00", to: "17:00" }],
    Sunday: [{ from: "09:00", to: "17:00" }],
  });

  const today = new Date();

  // Calculate the date for tomorrow
  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 5);

  const [disabledTiles, setDisabledTiles] = useState([]);

  const [dateRange, setDateRange] = useState([today, tomorrow]);




  useEffect(() => {

    setFormData({ appointments: item.appointments, schedule: item.schedule, serviceName: item.serviceName });
    setDateRange(item.daterange || [new Date(), new Date()])
    setWeeklyHours(item.availability || {
      Monday: [{ from: "09:00", to: "10:00" }],
      Tuesday: [{ from: "09:00", to: "17:00" }],
      Wednesday: [{ from: "09:00", to: "17:00" }],
      Thursday: [{ from: "09:00", to: "17:00" }],
      Friday: [{ from: "09:00", to: "17:00" }],
      Saturday: [{ from: "09:00", to: "17:00" }],
      Sunday: [{ from: "09:00", to: "17:00" }],
    })
    setDisabledTiles(item.disabledTiles || []);
  }, [item]);






  const handleChange = (event) => {
    const { name, value } = event.target;

    // Use a callback to correctly update nested properties
    setFormData((prevFormData) => {

      const updatedFormData = _.cloneDeep(prevFormData);;

      // Split the property name into an array to handle nested properties
      const propertyArray = name.split(".");


      // Traverse the nested structure to update the property
      let currentObject = updatedFormData;
      for (let i = 0; i < propertyArray.length - 1; i++) {
        currentObject = currentObject[propertyArray[i]] || {};
      }

      // Update the actual property value
      currentObject[propertyArray[propertyArray.length - 1]] = value;

      return updatedFormData;
    });
  };



  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{item.serviceName}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {/* Your existing content */}
        <Typography className="text-red-500">
          Note: Please Remeber to upload the before saving the services
        </Typography>

        {/* Additional input fields with headings */}
        <Typography
          style={{
            marginBottom: 10,
            marginTop: 10,
          }}
          variant="h6"
        >
          Schedule Details
        </Typography>
        <TextField
          label="Duration"
          type="number"
          name="schedule.duration"
          value={formData.schedule.duration}
          InputProps={{ inputProps: { min: 0 } }}
          variant="outlined"
          fullWidth
          onChange={handleChange}
          style={{
            marginTop: 10,
          }}
        />
        <div className="w-full mt-5">
          <ReusableDropdown
            helperText="Choose location type where meeting will be held"
            fullWidth={true}
            value={formData.schedule.location}
            label="Location"
            options={dropdownOptions.filter(i => enablePhysicalAddress || i.value !== 'offline')}

            onChange={(e) => {
              setFormData({
                ...formData,
                schedule: {
                  ...formData.schedule,
                  location: e.target.value,
                },
              });
            }}
          />
        </div>





        <div className="my-10">
          <div className="my-4">
            {defaultdaterange || defaultavailability ? <Button onClick={() => {
              setDateRange(defaultdaterange);
              setWeeklyHours(defaultavailability)
            }}><CopyAllOutlined /> Copy the Degault Settings</Button> : null}
          </div>
          <div>

            <Typography
              style={{
                marginBottom: 10,
                marginTop: 10,
              }}
              variant="h6"
            >
              Customize Slots
            </Typography>



            <BookingSlots disabledTiles={disabledTiles} setDisabledTiles={setDisabledTiles} weeklyHours={weeklyHours} setDateRange={setDateRange} dateRange={dateRange} setWeeklyHours={setWeeklyHours} />
          </div>
        </div>



        <Typography
          style={{
            marginBottom: 10,
            marginTop: 10,
          }}
          variant="h6"
        >
          Appointment Details
        </Typography>
        <TextField
          label="Before Each Appointment (in Minutes)"
          helperText="Time needed to prepare for appointment. We will ensure you have no booking scheduled for this interval before appointment"
          type="number"
          name="appointments.afterMinutes"
          value={formData.appointments.afterMinutes}
          InputProps={{ inputProps: { min: 0 } }}
          variant="outlined"
          onChange={handleChange}
          fullWidth
          style={{
            marginTop: 10,
          }}
        />
        <TextField
          label="After Each Appointment (in Minutes)"
          type="number"
          name="appointments.beforeMinutes"
          value={formData.appointments.beforeMinutes}
          helperText="Time needed after appointment for maintenance. We will ensure you have no booking scheduled for this interval after appointment "
          InputProps={{ inputProps: { min: 0 } }}
          variant="outlined"
          onChange={handleChange}
          fullWidth
          style={{
            marginTop: 10,
          }}
        />
        <TextField
          label="Cut-off Time"
          type="number"
          name="appointments.cutOffTime"
          value={formData.appointments.cutOffTime}
          variant="outlined"
          fullWidth
          onChange={handleChange}
          helperText="Customers can't book a slot before this interval (in minutes) from the time of booking. E.g If current time is 2:00PM and cut off time is 30 minutes. All available slots will start after 2:30PM"
          style={{
            marginTop: 10,
          }}
        />

        <TextField
          label="Max Future in Days"
          type="number"
          InputProps={{ inputProps: { min: 0 } }}
          name="appointments.maxDays"
          value={formData.appointments.maxDays}
          variant="outlined"
          fullWidth
          onChange={handleChange}
          helperText="Customers can't book a slot after this interval (in days) from the time of booking. E.g If max future day is 30, and its 15th Jan then all days after 15th Feb would be unavailable."
          style={{
            marginTop: 10,
          }}
        />

        <Button
          onClick={() => handleUpdate({
            ...formData,
            disabledTiles,
            customize: checked,
            daterange: dateRange,
            availability: weeklyHours
          })}
          variant="contained"
          color="primary"
          style={{
            marginTop: 10,
          }}
        >
          Update
        </Button>
        <div className="text-red-500">
          Please save the changes after every updates
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
