import React, { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import { ModalRoot } from "@mui/material";
import TextField from "@mui/material/TextField";
import CustomizedModals from "../Components/modal";
import { useDispatch, useSelector } from "react-redux";
import { clearState, createTeam, fetchTeams } from "../Redux/features/team";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import instance from "../config/axios";
import { IconShare } from "@tabler/icons-react";
import { setShowSnackBar } from "../Redux/features/snackBar";
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

export default function Team({ router }) {
  const isAdmin = useSelector((state) => state.admin.isAdmin);
  const teams = useSelector((state) => state.team.teams);
  const error = useSelector((state) => state.team.teamsevents.error);
  const loading = useSelector((state) => state.team.teamsevents.loading);
  const success = useSelector((state) => state.team.teamsevents.success);
  const [showAddTeams, setAddTeams] = useState(false);
  const [shareLoading, setLoading] = useState(false)
  const [deleteId, setDeleteId] = useState('')

  const [teamData, setTeamData] = useState({
    name: "",
    email: "",
  });
  const [errors, setErrors] = useState({
    name: false,
    email: false,
  });

  useEffect(() => {
    if (success !== "") {
      // Wait for 1 second and then change the state
      const timeoutId = setTimeout(() => {
        setAddTeams(false);
        dispatch(clearState());
      }, 1000);

      // Cleanup the timeout to avoid memory leaks
      return () => clearTimeout(timeoutId);
    }
  }, [success]);

  const handleDelete = async (id) => {
    try {
    setLoading(true)
      const res = await instance.delete(`/team/delete/${id}`);
      dispatch(fetchTeams());
      setDeleteId('')
      setLoading(false)
    } catch (err) {
      setDeleteId('')
      setLoading(false)
      console.log(err);
    }
  };

  const handleShare = async (id) => {
    try {
      setLoading(true)
      await instance.post(`team/share`, {
        id: id,
      });
      setLoading(false)
      dispatch(
        setShowSnackBar({
          show: true,
          message: "Email Sent to Expert",
          status: "success",
        })
      );
    } catch (err) {
      setLoading(false)
    }
  };

  const columns = [
    //{ field: "id", headerName: "ID", width: 80 }, // Adjust the width as needed
    {
      align: "center",
      headerAlign: "center",
      field: "name",
      headerName: "Name",
      width: 200,
    }, // Adjust the width as needed
    {
      align: "center",
      headerAlign: "center",
      field: "email",
      headerName: "Email",
      width: 400, // Adjust the width as needed
    },
    {
      align: "center",
      headerAlign: "center",
      field: "createdat",
      headerName: "Createdat",
      renderCell: (params) => (
        <div>{dayjs(params.value).format("DD/MM/YYYY HH:MM A")}</div>
      ),
      width: 250,
    }, // Adjust the width as needed
    {
      align: "center",
      headerAlign: "center",
      field: "actions",
      headerName: "Actions",
      renderCell: (params) => (
        <div>
          {/* Add your action buttons here */}
          <Link to={`/team/${params.row._id}`}>
            {" "}
            <button className="mr-5 text-blue-600">Edit</button>
          </Link>

          {isAdmin && (
            <>
              <button
                className="mr-5"
                onClick={() => setDeleteId(params.row._id)}
              >
                Delete
              </button>
              <Button
                onClick={() => {
                  handleShare(params.row._id);
                }}
                variant="contained"
              >
                <IconShare
                  style={{
                    marginRight: 10,
                  }}
                />
                {shareLoading ? "Loading..." : "Share"}
              </Button>
            </>
          )}
        </div>
      ),
      width: 250, // Adjust the width as needed
    },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTeams());
  }, []);

  const handleSave = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email validation regex

    if (teamData.name !== "" && teamData.email !== "") {
      // Check if both name and email are not empty
      if (emailRegex.test(teamData.email)) {
        // Check if the email is in a valid format
        setErrors({
          name: false,
          email: false,
        });
        dispatch(createTeam(teamData));
      } else {
        setErrors({
          email: true,
        });
      }
    } else {
      if (teamData.name === "") {
        setErrors({
          name: true,
        });
      } else {
        setErrors({
          email: true,
        });
      }
    }
  };

  const handleChange = (e) => {
    setTeamData({
      ...teamData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Layout>
      <div className="md:p-10 p-4 mt-10">
        {isAdmin && (
          <div className="flex mb-5 justify-end items-end ">
            <Button onClick={() => setAddTeams(true)}>Add Team</Button>
          </div>
        )}

        <div
          className="bg-white rounded-lg overflow-hidden"
          style={{ height: 400, width: "100%" }}
        >
          <DataGrid
            rows={teams}
            getRowId={(i) => i._id}
            columns={columns}
            pageSize={5} // Change this value as needed
            rowsPerPageOptions={[5, 10, 20]}
            pagination
          />
        </div>
      </div>
      <CustomizedModals
        title={"Add A Team"}
        handleSave={handleSave}
        loading={loading}
        handleClose={() => setAddTeams(false)}
        open={showAddTeams}
      >
        <div className="flex flex-col p-5 items-center justify-center w-[350px]">
          <TextField
            id="outlined-basic"
            fullWidth
            style={{ marginBottom: 20 }}
            label="Name"
            name="name"
            variant="outlined"
            error={errors.name} // This assumes errors.name is a boolean
            onChange={handleChange}
            helperText={errors.name ? "Please fill this field" : ""}
          />
          <TextField
            id="outlined-basic"
            fullWidth
            name="email"
            label="Email"
            variant="outlined"
            onChange={handleChange}
            error={errors.email} // This assumes errors.email is a boolean
            helperText={errors.email ? "Please Enter valid email" : ""}
          />
          {error && (
            <Alert style={{ marginTop: 10 }} severity="error">
              {error}
            </Alert>
          )}
          {success && (
            <Alert style={{ marginTop: 10 }} severity="success">
              {success}
            </Alert>
          )}
        </div>
      </CustomizedModals>
      <CustomizedModals customizedActions={true} open={deleteId !== ''}>
       <div>
       <DialogTitle>Delete Team</DialogTitle>
        <DialogContent>Are you sure want to delete this Team</DialogContent>
        <DialogActions>
          <Button onClick={()=>setDeleteId('')}>Cancel</Button>
          <Button color="error" onClick={()=>handleDelete(deleteId)} variant="contained">{shareLoading ? "Loading..." : "Delete"}</Button>

        </DialogActions>
       </div>
      </CustomizedModals>
    </Layout>
  );
}
