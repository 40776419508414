import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import instance from "../../config/axios";

const initialState = {
  show: false,
  message: "",
  status: "",
};

const snackBarSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setShowSnackBar: (state, action) => {
      state.show = action.payload.show;
      state.message = action.payload.message;
      state.status = action.payload.status;
    },
  },
});

export const { setShowSnackBar } = snackBarSlice.actions;
export default snackBarSlice.reducer;
