import { Button, Chip, Stack } from "@mui/material";
import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { IconTrash } from "@tabler/icons-react";
import dayjs from "dayjs";
import React, { useState } from "react";
import Calendar from "react-calendar";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import _ from 'lodash';



export default function BookingSlots({weeklyHours,setWeeklyHours,setDateRange,dateRange,disabledTiles,setDisabledTiles}) {

  const [removeTheSlots, setRemoveTheSlots] = useState(false);

  const handleChange = (dayIndex, slotIndex, field, value) => {
    setWeeklyHours((prevWeeklyHours) => {
      const newWeeklyHours = JSON.parse(JSON.stringify(prevWeeklyHours));
      // or using a library like lodash: const newWeeklyHours = _.cloneDeep(prevWeeklyHours);
  
      newWeeklyHours[dayIndex][slotIndex][field] = value;
  
      return newWeeklyHours;
    });
  };
  

  const disabledTileDates = disabledTiles.map(
    (dateString) => new Date(dateString)
  );

  const handleAddSlot = (dayIndex) => {
    setWeeklyHours((prevWeeklyHours) => {
      // Create a deep copy of the previous state
      const newWeeklyHours = JSON.parse(JSON.stringify(prevWeeklyHours));

      // Check if the dayIndex exists in the newWeeklyHours object
      if (!newWeeklyHours[dayIndex]) {
        newWeeklyHours[dayIndex] = []; // If not, initialize it as an array
      }

      // Add the new slot to the dayIndex array
      newWeeklyHours[dayIndex].push({ from: "09:00", to: "17:00" });

      return newWeeklyHours;
    });
  };

  const handleDeleteSlot = (dayIndex, slotIndex) => {
    setWeeklyHours((prevWeeklyHours) => {
      // Deep copy of the array and its contents
      const newWeeklyHours = _.cloneDeep(prevWeeklyHours);
  
      // Use splice to remove one element at the specified slotIndex
      newWeeklyHours[dayIndex].splice(slotIndex, 1);
  
    
  
      return newWeeklyHours;
    });
  };


  const handleDelete = (deletedDate) => () => {
    const updatedTiles = disabledTiles.filter((date) => date !== deletedDate);
    setDisabledTiles(updatedTiles);
  };
  
  const handleDateChange = (date) => {
    if (removeTheSlots) {
      if (disabledTiles.length > 0) {
        console.log(date, disabledTiles);

        setDisabledTiles([...disabledTiles, date]);
      } else {
        setDisabledTiles([date]);
      }
    } else {
      setDateRange(date);
    }
  };
  return (
    <div>
      <div>
        {Object.keys(weeklyHours).map((day, index) => (
          <div key={day} className="my-5">
            <h3>{day}</h3>
            {weeklyHours[day].map((slot, slotIndex) => {
              return (
                <div className="mb-5">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["MobileTimePicker"]}>
                      <div
                        key={slotIndex}
                        className="my-5 flex flex-row items-center justify-start"
                      >
                        <div className="mr-10">
                          <DemoItem label="From">
                            <MobileTimePicker
                              view={["hours", "minutes"]}
                              onAccept={(value) => {
                                const formattedTime =
                                  dayjs(value).format("HH:mm");
                                handleChange(
                                  day,
                                  slotIndex,
                                  "from",
                                  formattedTime
                                );
                              }}
                              value={dayjs(slot.from, "HH:mm")}
                            />
                          </DemoItem>
                        </div>
                        <DemoItem label="To">
                          <MobileTimePicker
                            view={["hours", "minutes"]}
                            value={dayjs(slot.to, "HH:mm")}
                            onAccept={(value) => {
                              const formattedTime =
                                dayjs(value).format("HH:mm");
                              handleChange(day, slotIndex, "to", formattedTime);
                            }}
                          />
                        </DemoItem>
                        <div className="flex h-full mt-7 ml-5 flex-col items-center justify-center">
                          <Button
                            onClick={() => handleDeleteSlot(day, slotIndex)}
                            variant="contained"
                            color="error"
                          >
                            <IconTrash />
                          </Button>
                        </div>
                      </div>
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              );
            })}
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleAddSlot(day)}
            >
              Add Slot
            </Button>
          </div>
        ))}
      </div>

      <div>
        <h1 className="py-5 text-left">
          Specific Day Availability (Add dates when your availability changes
          from your weekly hours)
        </h1>

        <div className="my-10 flex flex-col items-center justify-center">
          <Calendar
            className="w-full"
            value={dateRange}
            minDate={dayjs().toDate()}
            maxDate={dayjs().add(90, "days").toDate()}
            selectRange={!removeTheSlots}
            tileDisabled={({ date }) =>
              disabledTileDates.some((disabledDate) =>
                dayjs(disabledDate).isSame(date, "day")
              )
            }
            onChange={handleDateChange}
          />
        </div>

        <h1 className="py-5 text-left">Unavailable Slots</h1>
        <Stack direction="row" spacing={1}>
          {disabledTiles.map((item, index) => (
            <Chip
              key={index}
              label={dayjs(item).format("DD/MM/YYYY")}
              onDelete={handleDelete(item)}
              style={{ marginRight: 5, marginBottom: 10 }}
            />
          ))}
        </Stack>

        <Button
          color="primary"
          onClick={() => setRemoveTheSlots(!removeTheSlots)}
          variant="contained"
        >
          {removeTheSlots ? "Add Slots" : "Remove The Slots"}
        </Button>
      </div>
    </div>
  );
}
