import React, { useEffect } from "react";
import Layout from "../Layout/Layout";
import { useSelector, useDispatch } from "react-redux";
import { getMeetings } from "../Redux/features/booking";
import { DataGrid } from "@mui/x-data-grid";
import { Paper, Button, Alert } from "@mui/material";
import { Link } from "react-router-dom";
import { IconShare } from "@tabler/icons-react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import Typography from "@mui/material/Typography";

dayjs.extend(utc);
export default function Bookings() {
  const state = useSelector((state) => state.meetings.meetings);

  const columns = [
    {
      field: "owner",
      headerName: "Assigned Owner",
      width: 250, // Adjust the width as needed
    },
    {
      align: "left",
      headerAlign: "left",
      field: "name",
      headerName: "Host Name",
      width: 250,
    }, // Adjust the width as needed
    {
      align: "left",
      headerAlign: "left",
      field: "from",
      headerName: "From",
      type: "string",
      renderCell: (params) => (
        <div>
          <span>
            {console.log(params.row.slots[0])}
            {dayjs(params.row.slots[0]).utc().format("DD/MM/YYYY HH:mm A") +
              " " +
              "-" +
              " " +
              dayjs(params.row.slots[1]).utc().format("HH:mm A")}
          </span>
        </div>
      ),
      width: 320,
    },
    {
      align: "left",
      headerAlign: "left",
      field: "email",
      headerName: "User Email",
      type: "string",
      width: 220,
    }, // Adjust the width as needed
    {
      align: "center",
      headerAlign: "center",
      field: "actions",
      headerName: "Actions",
      renderCell: (params) => (
        <div>
          <Button disabled> Notify User</Button>
          {false && <> 
          <Button disabled color="error" variant="contained">
            Cancel Meeting
          </Button></>}
        </div>
      ),
      width: 350, // Adjust the width as needed
    },
  ];

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMeetings());
  }, []);

  return (
    <Layout>
      <div className="flex mt-10 w-full  items-center justify-center">
        <div>
          <Typography variant="h4" gutterBottom>
         Bookings
          </Typography>
        </div>
      </div>
      <div className="bg-white m-5 md:m-10">
        {state.data && (
          <DataGrid
            getRowId={(i) => i._id}
            rows={state.data}
            columns={columns}
            pageSize={5} // Change this value as needed
            rowsPerPageOptions={[5, 10, 20]}
            pagination
          />
        )}
      </div>
    </Layout>
  );
}
