import React, { useEffect, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import { Box, Button, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import {useDispatch} from 'react-redux'
import { setShowSnackBar } from '../Redux/features/snackBar';
const cities = ['Dubai', 'Abu Dhabi', 'Sharjah', 'Al Ain', 'Ajman', 'Ras Al Khaimah', 'Fujairah', 'Umm Al Quwain'];

export default function Physical_form({ handleSave, formfields, enablePhysicalAddress,checked,handleChange }) {
    const dispatch = useDispatch()
  
    const [formFields, setFormFields] = useState({
        address: '',
        address2: '',
        city: '',
        state: '',
        country: 'UAE',
    });
    const [formSubmitted, setFormSubmitted] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormFields((prevFormFields) => ({
            ...prevFormFields,
            [name]: value,
        }));
    };

  
    useEffect(() => {
        
     
        setFormFields({
            address:formfields.address || '',
            address2:formfields.address2 || '',
            city:formfields.city || '',
            state:formfields.state || '',
            country:formfields.country || ''
        })
    }, [formfields])

  



    const handleSubmit = () => {

        setFormSubmitted(true);

        if (formFields.address && formFields.city && formFields.state) {
            handleSave(checked, formFields)
            dispatch(setShowSnackBar({
                show:true,
           message: "Updated successfully",
            status: "success"
            }))
        } else {
            console.log('Error: Some required fields are empty.');
        }
    };

    return (
        <>
            <div className="my-4">
                <FormControlLabel control={<Checkbox checked={checked} onChange={handleChange} name="gilad" />} label="Enable Physical Address" />
            </div>
            {checked && (
                <FormControl fullWidth sx={{ mb: 5 }} component="fieldset" variant="standard">
                    <FormGroup>
                        <TextField
                            id="outlined-basic"
                            fullWidth
                            name="address"
                            label="address"
                            variant="outlined"
                            value={formFields.address}
                            onChange={handleInputChange}
                            error={formSubmitted && !formFields.address}
                            helperText={formSubmitted && !formFields.address ? 'Address is required' : ''}
                        />
                        <Box sx={{ mt: 2 }} />
                        <TextField
                            fullWidth
                            name="address2"
                            label="address2 (optional)"
                            value={formFields.address2}
                            variant="outlined"
                            onChange={handleInputChange}
                        />
                        <Box sx={{ mt: 2 }} />
                        <TextField
                            fullWidth
                            name="city"
                            label="city"
                            variant="outlined"
                            value={formFields.city}
                            onChange={handleInputChange}
                            error={formSubmitted && !formFields.city}
                            helperText={formSubmitted && !formFields.city ? 'City is required' : ''}
                        />
                        <Box sx={{ mt: 2 }} />
                        <Autocomplete
                            freeSolo
                            options={cities}
                            value={formFields.state}
                            onChange={(event, newValue) => setFormFields((prevFormFields) => ({ ...prevFormFields, state: newValue || '' }))}
                            inputValue={formFields.state}
                            onInputChange={(event, newInputValue) => setFormFields((prevFormFields) => ({ ...prevFormFields, state: newInputValue }))}
                            renderInput={(params) => (
                                <TextField {...params} label="State" variant="outlined" fullWidth name="state" error={formSubmitted && !formFields.state} helperText={formSubmitted && !formFields.state ? 'State is required' : ''} />
                            )}
                        />
                        <Box sx={{ mt: 2 }} />
                        <TextField
                            fullWidth
                            name="country"
                            label="country"
                            value={formFields.country}
                            variant="outlined"
                            error={formSubmitted && !formFields.country}
                            helperText={formSubmitted && !formFields.country ? 'Country is required' : ''}
                        />
                        <Box sx={{ mt: 2 }} />
                        <Button onClick={handleSubmit}>Submit</Button>
                    </FormGroup>
                </FormControl>
            )}
        </>
    );
}
