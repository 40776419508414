import React from "react";
import Layout from "../Layout/Layout";
import TeamDetail from "../Components/teamComponent";

export default function teamDetail() {
  return (
    <Layout>
      <TeamDetail />
    </Layout>
  );
}
