// axiosInstance.js

import axios from 'axios';
const token = localStorage.getItem('token')


const instance = axios.create({
  baseURL: '/', // Replace with your API base URL
  timeout: 5000, // Adjust timeout as needed
  headers: {
    'Content-Type': 'application/json',
    // Add any other headers you need
    'Authorization':`Bearer ${token}`
  },
});

export default instance;


